# ----------------------------------------
# Coffee script for ems_payment
# ----------------------------------------

# Global application definitions
window.ems_payment ?= {}
ems_payment.constants ?= {}

# Hide all "only_for_repeated_membership" class elements if checked is false
onlyForRepeatedMembershipEvent = (checked, init = false) ->
    only_for_repeated_membership = $(".only_for_repeated_membership")
    error_for_publication3 = $("#error_1_id_publication_3").length > 0 # At least one error for Publication #3?
    duration = if init then 0 else 400

    # Publication #3 field is also shown in case of server side error
    if checked or (init and error_for_publication3)
        only_for_repeated_membership.slideDown(duration)
    else
        only_for_repeated_membership.slideUp(duration)

# Try to validate a member for current year
validateMemberShipPaymentUniqueness = () ->
    # Prepare variables
    validationTimeout = 250 # milliseconds
    invalidClass = "is-invalid"
    emailInvalidFeedbackId = "email_invalid_feedback"
    clearTimeout(ems_payment.validateMemberShipPaymentUniquenessTimeout)

    ems_payment.validateMemberShipPaymentUniquenessTimeout = setTimeout ->
        emailElement = $("#id_email")
        email = emailElement.val()

        # Send AJAX request on a backend
        if email
            $.ajax
                url: ems_payment.constants.validateMemberShipPaymentUniquenessUrl
                method: "POST"
                data:
                    email: email
                    csrfmiddlewaretoken: ems_payment.constants.csrfToken

                success: (response) ->
                    if response?.member_ship_payment_exists
                        $("##{emailInvalidFeedbackId}").remove() # Delete it just for sure
                        emailElement.addClass(invalidClass)
                        emailElement.after("<div id='#{emailInvalidFeedbackId}' class=\"invalid-feedback\">#{response.error_message}</div>\n")
                    else  # Hide an alert
                        $("##{emailInvalidFeedbackId}").remove()
                        emailElement.removeClass(invalidClass)

                error: (xhr, status, error) ->
                    console.log("An error occurred while communicating with the server: #{error}")  # Just log the error

    , validationTimeout


$ ->
    # Set onclick for "is_repeated_membership" radio buttons and manualy init it's state
    $("#div_id_is_repeated_membership input[type='radio']").click (e)->
        onlyForRepeatedMembershipEvent((this.value == "1"))
    onlyForRepeatedMembershipEvent(document.getElementById("id_is_repeated_membership_1").checked, true)

    # Watch changes on all INPUTs of a FORM. It's not necessary now, but who knows about a future requirements
    $("#membership_payment_form input").on "input", ->
        validateMemberShipPaymentUniqueness()
